//import Alert from 'bootstrap/js/dist/alert'
//import Button from 'bootstrap/js/dist/button'
//import Carousel from 'bootstrap/js/dist/carousel'
import Collapse from 'bootstrap/js/dist/collapse'
//import Dropdown from 'bootstrap/js/dist/dropdown'
//import Offcanvas from 'bootstrap/js/dist/offcanvas'
//import Popover from 'bootstrap/js/dist/popover'
//import ScrollSpy from 'bootstrap/js/dist/scrollspy'
//import Tab from 'bootstrap/js/dist/tab'
//import Toast from 'bootstrap/js/dist/toast'
//import Tooltip from 'bootstrap/js/dist/tooltip'
import once from '@drupal/once'

var $component = once('processed-styleguide', 'body.styleguide').shift()
if ($component) {
  import('../../src/js/styleguide')
}
var $component = once("processed", ".component-gallery").shift();
if ($component) {
  import("../../templates/component/gallery/js/gallery")
}
var $component = once('processed', 'body').shift()
if ($component && typeof ccnstL !== 'undefined') {
  import('../../templates/component/cookieconsent/js/cookieconsent')
}
